import { AxiosResponse } from 'axios'
import { $axios } from './axios'

export type Photo = {
    url: string
    title: string
}

export const createPhotos = (body: Photo[]): Promise<AxiosResponse<Photo[]>> => {
    return $axios.post('/photos', body)
}

export const getPhotos = (): Promise<AxiosResponse<Photo[]>> => {
    return $axios.get('/photos')
}
