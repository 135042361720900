import { AxiosResponse } from 'axios'
import { $axios } from './axios'

export type Testimonial = {
    name: string
    quote: string
}

export const createTestimonials = (
    body: Testimonial[]
): Promise<AxiosResponse<Testimonial[]>> => {
    return $axios.post('/testimonials', body)
}

export const getTestimonials = (): Promise<AxiosResponse<Testimonial[]>> => {
    return $axios.get('/testimonials')
}
